import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import ArticlePreview from "../../templates/articlePreview";
import cx from "classnames";

class Recommended404 extends React.Component {

    constructor(props) {
        super(props);
        this.className = cx(
            "grid grid-cols-2 gap-6 lg:gap-x-10 gap-y-6",
            props.className
        )
    }

    renderItems() {
        return <StaticQuery
            query={graphql`
            query Recommended404 {
                allArticles: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {nin: ["39161838"]}}}, website: {elemMatch: {name: {eq: "pt"}}}}
                    sort: {publishedAt: DESC}
                    limit: 4
                ) {
                    nodes {
                        ...ArticleFieldsPlugin
                    }
                }
            }
        `}
            render={data =>
                data.allArticles.nodes
                    .map((article, index) => {
                    return <ArticlePreview className="col-span-1"
                                           data={article}
                                           noAuthor={true}
                                           noFlag={true}
                                           headingClass={"leading-snug text-sm font-semibold"}
                                           key={`404-${index}`}
                                           noBody={true}/>
                })
            }
        />
    }

    render() {
        return <div className={this.className} data-datocms-noindex>
            {this.renderItems()}
        </div>
    }
}

export default Recommended404;
